/* --------------------------------
   hero
-------------------------------- */

#hero {
    min-height: 475px;
    padding-top: 150px;
    padding-bottom: 30px;
    @extend %bg-cover;
    color: $white-color;

    .__title {
        line-height: 1.1;
        color: inherit;

        span {
            display: block;
            font-size: percentage(40/60);
        }
    }
}

.mobile-hero {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }
}

.desktop-hero {
    position: relative;

    @include media-breakpoint-down(md) {
        display: none;
    }

    &--overlay {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg,rgba(0,0,0,.2) 20%,rgba(0,0,0,.8) 100%);
        position: absolute;
    }

    > .container {
        position: absolute;
        width: 100%;
        left: 50%;
        right: 50%;
        transform: translate(-50%);
        height: 100%;
        display: flex;
        align-items: center;

        h1, p {
            color: white;
        }
    }

    aside {

        img {
            width: 100%;
        }
    }
}

.top-bar--style-2 + #hero {
    margin-top: 90px;
}

@include media-breakpoint-up(sm) {
    #hero {
        padding-top: 195px;
    }
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
    .top-bar--style-2 + #hero {
        margin-top: 130px;
    }
}

@include media-breakpoint-up(xl) {
}
